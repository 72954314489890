.cover {
  font-family: "Nunito";
  display: flex;
  flex-direction: column;
  padding-bottom: 180px;

  .row {
    display: flex;
    align-items: start;

    /* Media Query for Mobile Devices */
    @media (max-width: 480px) {
     align-items: center;
     flex-direction: column;
     text-align: center;
    }
      
    /* Media Query for low resolution  Tablets, Ipads */
    @media (min-width: 481px) and (max-width: 768px) {
      align-items: center;
      flex-direction: column;
      text-align: center;
    }

    &:last-of-type {
      margin-top: 32px;

      /* Media Query for Mobile Devices */
      @media (max-width: 480px) {
        margin-top: 0;
      }
    }

    .capitals {
      color: #13B6B8;
      font-size: 12px;
      margin-top: 0px;
      margin-bottom: 0px;
      letter-spacing: 4px;
      font-weight: bold;
    }

    .title {
      margin-top: 16px;
      margin-bottom: 0px;
      color: #171717;
      font-size: 30px;
      font-weight: bold;
      padding-bottom: 32px;
    }

    .subtitle {
      margin-top: 16px;
      margin-bottom: 0px;
      color: #171717;
      font-size: 20px;
      text-align: center;
    }

      .container {
        display: flex;
        // flex: 1.4;
        justify-content: center;
        text-align: center;

        /* Media Query for Mobile Devices */
        @media (max-width: 480px) {
          flex: 0;
        }
          
        /* Media Query for low resolution  Tablets, Ipads */
        @media (min-width: 481px) and (max-width: 768px) {
          flex: 0;
        }

        .image {
          width: 304px;
          box-shadow: 0px 25px 80px 0px rgba(0, 0, 0, 0.15);
          border-radius: 68px;
        }
      }

      .containerTwo {
        display: flex;
        justify-content: center;

        /* 
          ##Device = Desktops
          ##Screen = 1281px to higher resolution desktops
        */

        @media (min-width: 1281px) {
          flex: 0.7;
        }

        /* 
          ##Device = Laptops, Desktops
          ##Screen = B/w 1025px to 1280px
        */

        @media (min-width: 1025px) and (max-width: 1280px) {
          flex: 0.7;
        }

        /* 
          ##Device = Tablets, Ipads (portrait)
          ##Screen = B/w 768px to 1024px
        */

        @media (min-width: 768px) and (max-width: 1024px) {
          flex: 0,
        }

        /* 
          ##Device = Tablets, Ipads (landscape)
          ##Screen = B/w 768px to 1024px
        */

        @media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
          flex: 0,
        }

        /* 
          ##Device = Low Resolution Tablets, Mobiles (Landscape)
          ##Screen = B/w 481px to 767px
        */

        @media (min-width: 481px) and (max-width: 767px) {
          flex: 0,
        }

        /* 
          ##Device = Most of the Smartphones Mobiles (Portrait)
          ##Screen = B/w 320px to 479px
        */

        @media (min-width: 320px) and (max-width: 480px) {
          flex: 0,
        }
      }

      .column {
        display: flex;
        flex-direction: column;
        flex: 1;
        justify-content: center;
        align-items: center;

        /* Media Query for Mobile Devices */
        @media (max-width: 480px) {
          margin-right: 0;
          margin-left: 0;
          text-align: center;
          flex: 0;
          width: 70%;
        }
          
        /* Media Query for low resolution  Tablets, Ipads */
        @media (max-width: 480px) {
          margin-left: 0;
          margin-right: 0;
          text-align: center;
          flex: 0;
        }
        /* Media Query for Tablets Ipads portrait mode */
        @media (min-width: 481px) and (max-width: 768px) {
          margin-left: 0;
          margin-right: 0;
          text-align: center;
          flex: 0;
        }
      }

      .outerIconContainer {
        margin-left: 48px;
        margin-right: 48px;
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: center;


        /* Media Query for Mobile Devices */
        @media (max-width: 480px) {
          align-items: center;
          padding-top: 16px;
          padding-bottom: 16px;
        }
          
        /* Media Query for low resolution  Tablets, Ipads */
        @media (min-width: 481px) and (max-width: 768px) {
          align-items: center;
        }

    
        &:first-of-type {
          margin-left: 0;
        }

        &:last-of-type {
          margin-right: 0;
        }
  

          .subtitle {
            margin-left: 0;
            // margin-top: 16px;
            // margin-bottom: 16px;
            color: #171717;
            font-size: 20px;
          }


          .iconContainer {
            height: 60px;
            display: flex;
            align-items: center;

            /* Media Query for Mobile Devices */
            @media (max-width: 480px) {
              display: inline;
            }
        
            /* Media Query for low resolution  Tablets, Ipads */
            @media (min-width: 481px) and (max-width: 768px) {
              display: inline;
            }

            .icon {
              margin-top: 24px;
              margin-bottom: 24px;
              height: 50px;
            }

            .iconTwo {
              margin-top: 24px;
              margin-bottom: 24px;
              height: 50px;
            }
          }
        }
  }
}
