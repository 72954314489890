.cover {
  font-family: "Nunito";
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 180px;

  .capitals {
    color: #13B6B8;
    font-size: 12px;
    margin-top: 0px;
    margin-bottom: 0px;
    letter-spacing: 4px;
    font-weight: bold;
  }

  .title {
    margin-top: 16px;
    margin-bottom: 0px;
    color: #171717;
    font-size: 32px;
    font-weight: bold;

    /* Media Query for Mobile Devices */
    @media (max-width: 480px) {
      margin-left: 40px;
      margin-right: 40px;
      text-align: center;
    }
        
    /* Media Query for low resolution  Tablets, Ipads */
    @media (min-width: 481px) and (max-width: 768px) {
      margin-left: 40px;
      margin-right: 40px;
      text-align: center;
    }
  }

  .subtitle {
    margin-top: 16px;
    margin-bottom: 0px;
    color: #171717;
    font-size: 20px;
  }

  .row {
    display: flex;
    flex-direction: row;

    /* Media Query for Mobile Devices */
    @media (max-width: 480px) {
      flex-direction: column;
    }
      
    /* Media Query for low resolution  Tablets, Ipads */
    @media (min-width: 481px) and (max-width: 768px) {
      flex-direction: column;
    }

    .column {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;

      &:first-of-type {
        padding-right: 232px;

        /* Media Query for Mobile Devices */
        @media (max-width: 480px) {
          padding-right: 0;
        }
          
        /* Media Query for low resolution  Tablets, Ipads */
        @media (min-width: 481px) and (max-width: 768px) {
          padding-right: 0;
        }
      }

      .image {
        margin-top: 42px;
        height: 304px;
        box-shadow: 0px 25px 80px 0px rgba(0, 0, 0, 0.15);
        border-radius: 78px;
      }

      .row {
        display: flex;
        align-items: end;
        border-bottom: 2px solid black;

        span {
          cursor: pointer; 
          font-weight: bold;
          text-decoration: none;
          cursor: pointer; 
        }

        .image {
          height: 18px;
          width: 20px;
          padding-left: 6px;
        }
      }
  
      .text {
        margin-bottom: 0px;
        color: #171717;
        font-size: 20px;
      }
    }
  }
}
