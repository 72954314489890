.cover {
  font-family: "Nunito";
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding-bottom: 180px;

  .image {
    margin-top: 42px;
    height: 304px;
    box-shadow: 0px 25px 80px 0px rgba(0, 0, 0, 0.15);
    border-radius: 78px;
  }

  .capitals {
    color: #13B6B8;
    font-size: 12px;
    margin-top: 0px;
    margin-bottom: 0px;
    letter-spacing: 4px;
    font-weight: bold;
  }

  .title {
    color: #171717;
    font-size: 32px;
    font-weight: bold;
    margin-top: 16px;
    margin-bottom: 0;

    /* Media Query for Mobile Devices */
    @media (max-width: 480px) {
      padding-left: 44px;
      padding-right: 44px;

    }
      
    /* Media Query for low resolution  Tablets, Ipads */
    @media (min-width: 481px) and (max-width: 768px) {
      padding-left: 44px;
      padding-right: 44px;
    }
  }
}
