.cover {
  font-family: "Nunito";
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 180px;

  .embla {
    overflow: hidden;
    width: 100%;
    cursor: grabbing;
    margin-top: 42px;
  }

  .embla__container {
    display: flex;
    margin-left: 20px;
    margin-right: 20px;
  }

  .embla__slide {
    margin: 20px;
    flex: 0 0 16%;
    height: 240px;
    min-width: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 12px 12px 0px rgba(0, 0, 0, 0.15);
    background: linear-gradient(90deg, rgb(19, 182, 184) 0%, rgb(73, 208, 190) 100%);
    border-radius: 64px;

      /* 
        ##Device = Tablets, Ipads (portrait)
        ##Screen = B/w 768px to 1024px
      */

      @media (min-width: 768px) and (max-width: 1024px) {
        flex: 0 0 28%;
      }

      /* 
        ##Device = Tablets, Ipads (landscape)
        ##Screen = B/w 768px to 1024px
      */

      @media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
        flex: 0 0 28%;
      }

      /* 
        ##Device = Low Resolution Tablets, Mobiles (Landscape)
        ##Screen = B/w 481px to 767px
      */

      @media (min-width: 481px) and (max-width: 767px) {
        flex: 0 0 28%;
      }

      /* 
        ##Device = Most of the Smartphones Mobiles (Portrait)
        ##Screen = B/w 320px to 479px
      */

      @media (min-width: 320px) and (max-width: 480px) {
        flex: 0 0 60%;
      }
          
    .column {
      display: flex;
      flex-direction: column;

      .beast {
        width: 140px;
        box-shadow: 0px 12px 12px 0px rgba(0, 0, 0, 0.20);
        border-radius: 100%;
      }

      .unknownBeast {
        width: 140px;
        filter: drop-shadow(4px 4px 10px rgba(0,0,0,0.3));
      }

      p {
        margin: 0;
        margin-top: 16px;
        margin-bottom: 0px;
        color: #171717;
        font-size: 20px;
        text-align: center;
        font-weight: bold;
      }
    }
  }

  .buttonRow {
    display: flex;

    .embla__button {
      width: 40px;
      height: 40px;
      border-radius: 80px;
      border: none;
      cursor: pointer;
      background: linear-gradient(180deg, #2E2E2E 0%, #171717 100%);
      box-shadow: 0px 4px 10px 4px rgba(23, 23, 23, 0.30);
      color: white;
      font-weight: bold;
      font-family: "Nunito";
      margin-top: 16px;
      margin-left: 16px;
      margin-right: 16px;
      transition: transform 200ms ease;

       /* Media Query for Mobile Devices */
       @media (max-width: 480px) {
        margin-right: 40px;
       }

      &:hover {
        transform: translate(0px, -2px);
        transition-timing-function: ease-in;
        transition: 0.5s;
      }

      &:first-of-type span {
        display: inline-block;
        transform: rotate(-180deg);
      }
    }

  }

  .text {
    color: black;
  }

  .capitals {
    color: #13B6B8;
    font-size: 12px;
    margin-top: 0px;
    margin-bottom: 0px;
    letter-spacing: 4px;
    font-weight: bold;
  }

  .title {
    margin-top: 16px;
    margin-bottom: 0px;
    color: #171717;
    font-size: 32px;
    font-weight: bold;
    text-align: center;

    /* Media Query for Mobile Devices */
    @media (max-width: 480px) {
      margin-left: 40px;
      margin-right: 40px;
      text-align: center;
    }
        
    /* Media Query for low resolution  Tablets, Ipads */
    @media (min-width: 481px) and (max-width: 768px) {
      margin-left: 40px;
      margin-right: 40px;
      text-align: center;
    }
  }

  .subtitle {
    margin-top: 16px;
    margin-bottom: 0px;
    color: #171717;
    font-size: 20px;
  }

  .row {
    display: flex;
    flex-direction: row;

    /* Media Query for Mobile Devices */
    @media (max-width: 480px) {
      flex-direction: column;
    }
      
    /* Media Query for low resolution  Tablets, Ipads */
    @media (min-width: 481px) and (max-width: 768px) {
      flex-direction: column;
    }

    .column {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;

      &:first-of-type {
        padding-right: 232px;

        /* Media Query for Mobile Devices */
        @media (max-width: 480px) {
          padding-right: 0;
        }
          
        /* Media Query for low resolution  Tablets, Ipads */
        @media (min-width: 481px) and (max-width: 768px) {
          padding-right: 0;
        }
      }

      .image {
        margin-top: 42px;
        height: 304px;
        box-shadow: 0px 25px 80px 0px rgba(0, 0, 0, 0.15);
        border-radius: 78px;
      }

      .row {
        display: flex;
        align-items: end;
        border-bottom: 2px solid black;

        span {
          cursor: pointer; 
          font-weight: bold;
          text-decoration: none;
          cursor: pointer; 
        }

        .image {
          height: 18px;
          width: 20px;
          padding-left: 6px;
        }
      }
  
      .text {
        margin-bottom: 0px;
        color: #171717;
        font-size: 20px;
      }
    }
  }
}
