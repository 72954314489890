.cover {
  font-family: "Nunito";
  display: flex;
  align-items: center;
  padding-top: 180px;
  padding-bottom: 180px;
  justify-content: center;

      /* 
        ##Device = Tablets, Ipads (portrait)
        ##Screen = B/w 768px to 1024px
      */

      @media (min-width: 768px) and (max-width: 1024px) {
        flex-direction: column;
        padding-left: 180px;
        padding-right: 180px;
        padding-top: 180px;
        padding-bottom: 180px;
      }

      /* 
        ##Device = Tablets, Ipads (landscape)
        ##Screen = B/w 768px to 1024px
      */

      @media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
        padding-left: 180px;
        padding-right: 180px;
        padding-top: 180px;
        padding-bottom: 180px;
      }

      /* 
        ##Device = Low Resolution Tablets, Mobiles (Landscape)
        ##Screen = B/w 481px to 767px
      */

      @media (min-width: 481px) and (max-width: 767px) {
        padding-left: 64px;
        padding-right: 64px;
        padding-top: 180px;
        padding-bottom: 180px;
      }

      /* 
        ##Device = Most of the Smartphones Mobiles (Portrait)
        ##Screen = B/w 320px to 479px
      */

      @media (min-width: 320px) and (max-width: 480px) {
        flex-direction: column;
        padding-left: 64px;
        padding-right: 64px;
        padding-top: 180px;
        padding-bottom: 180px;
      }

    .container {
      display: flex;
      flex: 1.8;
      justify-content: center;

      /* 
        ##Device = Desktops
        ##Screen = 1281px to higher resolution desktops
      */

      @media (min-width: 1281px) {
        flex: 1.8;
      }

      /* 
        ##Device = Laptops, Desktops
        ##Screen = B/w 1025px to 1280px
      */

      @media (min-width: 1025px) and (max-width: 1280px) {
        flex: 1.8;
      }

      /* 
        ##Device = Tablets, Ipads (portrait)
        ##Screen = B/w 768px to 1024px
      */

      @media (min-width: 768px) and (max-width: 1024px) {
        flex: 1,
      }

      /* 
        ##Device = Tablets, Ipads (landscape)
        ##Screen = B/w 768px to 1024px
      */

      @media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
        flex: 0,
      }

      /* 
        ##Device = Low Resolution Tablets, Mobiles (Landscape)
        ##Screen = B/w 481px to 767px
      */

      @media (min-width: 481px) and (max-width: 767px) {
        flex: 0;
      }

      /* 
        ##Device = Most of the Smartphones Mobiles (Portrait)
        ##Screen = B/w 320px to 479px
      */

      @media (min-width: 320px) and (max-width: 480px) {
        flex: 0;
      }

      .image {
        width: 304px;
        box-shadow: 0px 25px 80px 0px rgba(0, 0, 0, 0.15);
        border-radius: 80px;
      }
    }

    .containerTwo {
      display: flex;
      justify-content: center;

      /* 
        ##Device = Desktops
        ##Screen = 1281px to higher resolution desktops
      */

      @media (min-width: 1281px) {
        flex: 1,
      }

      /* 
        ##Device = Laptops, Desktops
        ##Screen = B/w 1025px to 1280px
      */

      @media (min-width: 1025px) and (max-width: 1280px) {
        flex: 1,
      }

      /* 
        ##Device = Tablets, Ipads (portrait)
        ##Screen = B/w 768px to 1024px
      */

      @media (min-width: 768px) and (max-width: 1024px) {
        flex: 1,
      }

      /* 
        ##Device = Tablets, Ipads (landscape)
        ##Screen = B/w 768px to 1024px
      */

      @media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
        flex: 0,
      }

      /* 
        ##Device = Low Resolution Tablets, Mobiles (Landscape)
        ##Screen = B/w 481px to 767px
      */

      @media (min-width: 481px) and (max-width: 767px) {
        flex: 0,
      }

      /* 
        ##Device = Most of the Smartphones Mobiles (Portrait)
        ##Screen = B/w 320px to 479px
      */

      @media (min-width: 320px) and (max-width: 480px) {
        flex: 0,
      }
    }

      .column {
        display: flex;
        flex-direction: column;
        justify-content: center;

        /* 
          ##Device = Desktops
          ##Screen = 1281px to higher resolution desktops
        */

        @media (min-width: 1281px) {
          padding-right: 80px;
          flex: 1.8;
        }

        /* 
          ##Device = Laptops, Desktops
          ##Screen = B/w 1025px to 1280px
        */

        @media (min-width: 1025px) and (max-width: 1280px) {
          flex: 1.8;
        }

        /* 
          ##Device = Tablets, Ipads (portrait)
          ##Screen = B/w 768px to 1024px
        */

        @media (min-width: 768px) and (max-width: 1024px) {
          flex: 1;
          margin-top: 64px;
          text-align: center;
        }

        /* 
          ##Device = Tablets, Ipads (landscape)
          ##Screen = B/w 768px to 1024px
        */

        @media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
          flex: 1;
          margin-top: 64px;
          text-align: center;
        }

        /* 
          ##Device = Low Resolution Tablets, Mobiles (Landscape)
          ##Screen = B/w 481px to 767px
        */

        @media (min-width: 481px) and (max-width: 767px) {
          flex: 1;
          margin-left: 0;
          padding-right: 0;
          margin-top: 56px;
          text-align: center;
        }

        /* 
          ##Device = Most of the Smartphones Mobiles (Portrait)
          ##Screen = B/w 320px to 479px
        */

        @media (min-width: 320px) and (max-width: 480px) {
          flex: 1;
          margin-left: 0;
          margin-top: 64px;
          text-align: center;
          padding-right: 0;
          margin-top: 56px;
          text-align: center;
        }

        .capitals {
          color: #13B6B8;
          font-size: 12px;
          margin-top: 0px;
          margin-bottom: 0px;
          letter-spacing: 4px;
          font-weight: bold;
        }

        .title {
          margin-top: 16px;
          margin-bottom: 0px;
          color: #171717;
          font-size: 30px;
          font-weight: bold;
        
          /* 
            ##Device = Desktops
            ##Screen = 1281px to higher resolution desktops
          */

          @media (min-width: 1281px) {
            max-width: 300px;
          }

          /* 
            ##Device = Laptops, Desktops
            ##Screen = B/w 1025px to 1280px
          */

          @media (min-width: 1025px) and (max-width: 1280px) {
            max-width: 300px;
          }
        }

        .subtitle {
          margin-top: 16px;
          margin-bottom: 0px;
          color: #171717;
          font-size: 20px;
        }
    
  }
}
