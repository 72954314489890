.cover {
  font-family: "Nunito";
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  background-image: linear-gradient(90deg, rgba(19, 182, 184, 1) 0%, rgba(73, 208, 190, 1) 100%);

  @media (max-width: 768px) {
    padding: 20px;
  }

  .navButton {
    cursor: pointer;
    height: 48px;
    width: 260px;
    border-radius: 25px;
    background: linear-gradient(180deg, #2E2E2E 0%, #171717 100%);
    box-shadow: 0px 4px 10px 4px rgba(23, 23, 23, 0.30);
    color: white;
    border-radius: 12px;
    text-decoration: none;
    outline: none;
    border: none;
    font-weight: bold;
    font-family: "Nunito";
    display: flex;
    justify-content: center;
    align-items: center;
    transition: transform 200ms ease;
    font-size: 14px;
    margin-right: 4%;

    &:hover {
      transform: translate(0px, -2px);
    }
  }
}