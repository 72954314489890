.footer{
  background: #171717;
  padding: 40px 0;
  position: absolute;
  width: 100%;

  .container{
    max-width: 1170px;
    margin:auto;
    font-family: "Nunito";
  }

  .copyright {
    color: #ffffff;
    padding-top: 32px;
    text-align: center;
  }

  .row{
    display: flex;

    @media (max-width: 768px) {
      flex-direction: column;
    }
  
  }

  @media(max-width: 767px){
    .footerCol{
      width: 50%;
      margin-bottom: 30px;
    }
  }
  
  @media(max-width: 574px){
    .footerCol{
      width: 100%;
    }
  }

  .footerCol{
    width: 33%;
    padding: 0 15px;


    ul{
      list-style: none;
      padding-left: 0px;
    }

    ul li:not(:last-child){
      margin-bottom: 10px;
    }

    ul li a{
      font-size: 16px;
      text-transform: capitalize;
      color: #ffffff;
      text-decoration: none;
      font-weight: 300;
      color: #bbbbbb;
      display: block;
      transition: all 0.3s ease;
    }

    ul li button{
      font-size: 16px;
      text-transform: capitalize;
      color: #ffffff;
      text-decoration: none;
      font-weight: 300;
      color: #bbbbbb;
      display: block;
      transition: all 0.3s ease;
      border: none;
      background: none;
    }

    .innerContainer {
      display: flex;
    }

    .innerContainer a{
      height: 40px;
      width: 40px;
      background-color: #171717;
      margin:0 20px 20px 0;
      text-align: center;
      line-height: 40px;
      border-radius: 50%;
      color: #ffffff;
      transition: all 0.5s ease;

      display: flex;
      justify-content: center;
      align-items: center;

      .rotateBolt {
        transform: scaleX(-1) rotate(116deg);
        position: relative;
        left: -6px;
      }
    }

    .innerContainer a:hover{
      color: #24262b;
      background-color: #ffffff;
    }

    ul li a:hover{
      color: #ffffff;
      padding-left: 8px;
    }
    
    h4{
      font-size: 18px;
      color: #ffffff;
      text-transform: capitalize;
      margin-bottom: 35px;
      font-weight: 500;
      position: relative;
    }

    h4::before{
      content: '';
      position: absolute;
      left:0;
      bottom: -10px;
      background-color: #ffffff;
      border-radius: 50px;
      height: 2px;
      box-sizing: border-box;
      width: 50px;
    }
 }
}
