.cover {
  font-family: "Nunito";
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 180px;

  .container {
    display: flex;
    flex-direction: row;
    margin-top: 42px;
        
    .spaceContainer {
      flex: 0.5 1;
    }

    .cornerWrapper {
      padding: 16px;
      display: block;
      box-shadow: 0px 12px 12px 0px rgba(0, 0, 0, 0.15);
      border-radius: 18px;
      line-height: 0px;
      border: 3px solid #eee;
    }

    iframe, object, embed {
      width: 61em;
      height: 30em;

      /* 
        ##Device = Tablets, Ipads (portrait)
        ##Screen = B/w 768px to 1024px
      */

      @media (min-width: 768px) and (max-width: 1024px) {
        width: 40em;
        height: 20em;
      }


      /* 
        ##Device = Tablets, Ipads (landscape)
        ##Screen = B/w 768px to 1024px
      */

      @media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
        width: 40em;
        height: 20em;
      }

      /* 
        ##Device = Low Resolution Tablets, Mobiles (Landscape)
        ##Screen = B/w 481px to 767px
      */

      @media (min-width: 481px) and (max-width: 767px) {
        width: 20em;
        height: 20em;
      }

      /* 
        ##Device = Most of the Smartphones Mobiles (Portrait)
        ##Screen = B/w 320px to 479px
      */

      @media (min-width: 320px) and (max-width: 480px) {
        width: 20em;
        height: 20em;
      }
    }

    .column {
      display: flex;
      flex-direction: column;
      align-items: center;
      flex: 0.8;

      .subtitle {
        margin-top: 16px;
        margin-bottom: 0px;
        color: #171717;
        font-size: 20px;
        text-align: center;
      }
  
      .row {
        display: flex;
    
        .navButton {
          margin-top: 40px;
          cursor: pointer;
          height: 48px;
          width: 240px;
          border-radius: 25px;
          background: linear-gradient(180deg, #2E2E2E 0%, #171717 100%);
          box-shadow: 0px 4px 12px 4px rgba(0, 0, 0, 0.38);
          color: white;
          border-radius: 12px;
          text-decoration: none;
          outline: none;
          border: none;
          font-weight: bold;
          font-family: "Nunito";
          display: flex;
          justify-content: center;
          align-items: center;
          transition: transform 200ms ease;
          font-size: 14px;
          
          &:hover {
            transform: translate(0px, -2px);
          }
        }
    
        .navButtonTwo {
          margin-top: 40px;
          cursor: pointer;
          height: 48px;
          width: 148px;
          border-radius: 25px;
          background: linear-gradient(180deg, #ffff 0%, #d9d9d9 100%);
          box-shadow: 0px 4px 12px 4px rgba(23, 23, 23, 0.30);
          color: black;
          border-radius: 12px;
          text-decoration: none;
          outline: none;
          font-weight: bold;
          font-family: "Nunito";
          display: flex;
          justify-content: center;
          align-items: center;
          transition: transform 200ms ease;
          font-size: 14px;
      
          @media (max-width: 768px) {
            width: 120px;
          }
      
          &:hover {
            transform: translate(0px, -2px);
          }
        }
      }
    }
  }

  .capitals {
    color: #13B6B8;
    font-size: 12px;
    margin-top: 0px;
    margin-bottom: 0px;
    letter-spacing: 4px;
    font-weight: bold;
  }

  .title {
    margin-top: 16px;
    margin-bottom: 0px;
    color: #171717;
    font-size: 32px;
    font-weight: bold;
    text-align: center;

    @media (max-width: 480px) {
      width: 74%;
    }

  }

  .image {
    margin-top: 40px;
    height: 500px;
  }
}
