.cover {
  font-family: "Nunito";
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  background-image: url(../../../images/gold.jpg);
  background-position: top top;
  background-size: cover;
  margin: 0;  padding-left: 200px;
  padding-right: 200px;
  height: 100vh;

  h1 {
    z-index: 2;
    font-size: 3.5em;
  }

  .text {
    z-index: 2;
    font-weight: normal;
    font-size: 2em;
  }

  .innerCover {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    text-align: center;
    backdrop-filter: blur(10px);
    background: linear-gradient(
      0deg,
      rgba(19, 182, 184, 1) 0%,
      rgba(19, 182, 184, 1) 20%,
      rgba(19, 182, 184, 0.6) 100%
    );
  }

  .container {
    width: 18em;
    height: 18em;
    background-color: rgba(255, 255, 255, 0.3);
    z-index: 1;
    border-radius: 60px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 8px 8px 0px rgb(0 0 0 / 5%);

    p {
      font-weight: 600;
      color: rgb(53, 162, 164)
    }

    .ldsDualRing {
      color: rgb(53, 162, 164)
    }
    .ldsDualRing,
    .ldsDualRing:after {
      box-sizing: border-box;
    }
    .ldsDualRing {
      display: inline-block;
      width: 80px;
      height: 80px;
    }
    .ldsDualRing:after {
      content: " ";
      display: block;
      width: 64px;
      height: 64px;
      margin: 8px;
      border-radius: 50%;
      border: 6.4px solid currentColor;
      border-color: currentColor transparent currentColor transparent;
      animation: ldsDualRing 1.2s linear infinite;
    }
    @keyframes ldsDualRing {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  }
  
  .wonContainer {
    width: 18em;
    height: 18em;
    background-color: rgba(255, 255, 255, 0.8);
    z-index: 1;
    border-radius: 60px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 8px 8px 0px rgb(0 0 0 / 5%);

    .image {
      width: 60%;
      height: 60%;
    }
  }
}
