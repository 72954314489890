.nav {
  width: 100%;
  position: fixed;
  transition-timing-function: ease-in;
  transition: 0.5s;
  top: -81px;
  z-index: 100;
  height: 80px;
  justify-content: space-between;
  align-items: center;
  display: flex;

  .jippiLogo {
    margin-top: 13px;
    width: 120px;
    height: 60px;
    background-image: url(../../images/jippi_black.png);
    background-repeat: round;
    margin-left: 4%;

    /* Media Query for Mobile Devices */
    @media (max-width: 480px) {
      width: 80px;
      height: 40px;
    }
      
    /* Media Query for low resolution  Tablets, Ipads */
    @media (min-width: 481px) and (max-width: 767px) {
      width: 120px;
      height: 60px;
    }
      
    /* Media Query for Tablets Ipads portrait mode */
    @media (min-width: 768px) and (max-width: 1024px){
      width: 120px;
      height: 60px;
    }
      
    /* Media Query for Laptops and Desktops */
    @media (min-width: 1025px) and (max-width: 1280px){
      width: 120px;
      height: 60px;
    }
      
    /* Media Query for Large screens */
    @media (min-width: 1281px) {
      width: 120px;
      height: 60px;
    }
  }

  .navButton {
    cursor: pointer;
    height: 48px;
    width: 112px;
    border-radius: 100px;
    background: linear-gradient(180deg, #2E2E2E 0%, #171717 100%);
    box-shadow: 0px 4px 10px 4px rgba(23, 23, 23, 0.30);
    color: white;
    text-decoration: none;
    outline: none;
    border: none;
    font-weight: bold;
    font-family: "Nunito";
    display: flex;
    justify-content: center;
    align-items: center;
    transition: transform 200ms ease;
    font-size: 14px;
    margin-right: 4%;

    @media (max-width: 768px) {
      width: 88px;
    }

    &:hover {
      transform: translate(0px, -2px);
    }
  }
}

.navBlue {
  position: fixed;
  top: 0px;
}
