.cover {
  font-family: "Nunito";
  display: flex;
  align-items: center;
  padding-bottom: 280px;
  justify-content: center;

    /* Media Query for Mobile Devices */
    @media (max-width: 480px) {
      flex-direction: column;
      padding-left: 64px;
      padding-right: 64px;
      padding-top: 180px;
      padding-bottom: 180px;
    }
      
    /* Media Query for low resolution  Tablets, Ipads */
    @media (min-width: 481px) and (max-width: 768px) {
      flex-direction: column;
      padding-left: 64px;
      padding-right: 64px;
      padding-top: 180px;
      padding-bottom: 180px;
    }

    .container {
      display: flex;
      flex: 1.8;
      justify-content: center;

      .image {
        width: 304px;
        box-shadow: 0px 25px 80px 0px rgba(0, 0, 0, 0.15);
        border-radius: 200px;
      }
    }

    .containerTwo {
      display: flex;
      flex: 1;
      justify-content: center;
    }

      .column {
        display: flex;
        flex-direction: column;
        flex: 1.8;
        justify-content: center;
        padding-right: 80px;

        .innerContainerTwo { 
          display: flex;
          flex-direction: 'row';
          padding-top: 32px;
  
          .badge {
            cursor: pointer;
            width: 148px;
            transition: transform 200ms ease;
            box-shadow: 0px 4px 10px 4px rgba(23, 23, 23, 0.30);
            border-radius: 8px;

            &:hover {
              transform: translate(0px, -2px);
              transition-timing-function: ease-in;
              transition: 0.5s;
            }
  
            &:first-child {
              margin-right: 12px;
            }
          }
        }

        /* Media Query for Mobile Devices */
        @media (max-width: 480px) {
          margin-left: 0;
          margin-top: 64px;
          text-align: center;
          padding-right: 0;
        }
          
        /* Media Query for low resolution  Tablets, Ipads */
        @media (max-width: 480px) {
          margin-left: 0;
          margin-top: 64px;
          text-align: center;
          padding-right: 0;
        }
        /* Media Query for Tablets Ipads portrait mode */
        @media (min-width: 481px) and (max-width: 768px) {
          margin-left: 0;
          margin-top: 64px;
          text-align: center;
          padding-right: 0;
        }

        .capitals {
          color: #13B6B8;
          font-size: 12px;
          margin-top: 0px;
          margin-bottom: 0px;
          letter-spacing: 4px;
          font-weight: bold;
        }

        .title {
          margin-top: 16px;
          margin-bottom: 0px;
          color: #171717;
          font-size: 32px;
          font-weight: bold;
        }

        .subtitle {
          margin-top: 16px;
          margin-bottom: 0px;
          color: #171717;
          font-size: 20px;
        }
    
  }
}
