.cover {
  font-family: "Nunito";
  display: flex;
  align-items: center;
  padding-top: 180px;
  padding-bottom: 180px;
  justify-content: center;

    /* Media Query for Mobile Devices */
    @media (max-width: 480px) {
      flex-direction: column;
      padding-left: 64px;
      padding-right: 64px;
      padding-top: 180px;
      padding-bottom: 180px;
    }
      
    /* Media Query for low resolution  Tablets, Ipads */
    @media (min-width: 481px) and (max-width: 768px) {
      flex-direction: column;
      padding-left: 64px;
      padding-right: 64px;
      padding-top: 180px;
      padding-bottom: 180px;
    }

    .container {
      display: flex;
      flex: 1.8;
      justify-content: center;

      .image {
        width: 304px;
        box-shadow: 0px 25px 80px 0px rgba(0, 0, 0, 0.15);
        border-radius: 68px;
      }
    }

    .containerTwo {
      display: flex;
      flex: 1;
      justify-content: center;
    }

      .column {
        display: flex;
        flex-direction: column;
        flex: 1.8;
        justify-content: center;
        padding-right: 80px;

        /* Media Query for Mobile Devices */
        @media (max-width: 480px) {
          margin-left: 0;
          margin-top: 64px;
          text-align: center;
          padding-right: 0;
        }
          
        /* Media Query for low resolution  Tablets, Ipads */
        @media (max-width: 480px) {
          margin-left: 0;
          margin-top: 64px;
          text-align: center;
          padding-right: 0;
        }
        /* Media Query for Tablets Ipads portrait mode */
        @media (min-width: 481px) and (max-width: 768px) {
          margin-left: 0;
          margin-top: 64px;
          text-align: center;
          padding-right: 0;
        }

        .capitals {
          color: #13B6B8;
          font-size: 12px;
          margin-top: 0px;
          margin-bottom: 0px;
          letter-spacing: 4px;
          font-weight: bold;
        }

        .title {
          margin-top: 16px;
          margin-bottom: 0px;
          color: #171717;
          font-size: 32px;
          font-weight: bold;
        }

        .subtitle {
          margin-top: 16px;
          margin-bottom: 0px;
          color: #171717;
          font-size: 20px;
        }
    
  }
}
