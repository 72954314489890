@font-face {
  font-family: "Nunito";
  font-weight: 400;
  src: local("Nunito"),
  url("./fonts/Nunito-VariableFont_wght.ttf") format("truetype");
}

@font-face {
  font-family: "JippiFont";
  font-weight: 200;
  src: local("JippiFont"),
  url("./fonts/jippi_font.ttf") format("truetype");
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.swal2-popup {
  font-family: "Nunito", serif;
}
