.cover {
  font-family: "Nunito";
  height: 100vh;
  display: flex;
  flex-direction: column;
  background-image: linear-gradient(90deg, rgba(19, 182, 184, 1) 0%, rgba(73, 208, 190, 1) 100%);
  padding-left: 40px;
  padding-right: 40px;
  overflow-y: auto;

  @media (max-width: 768px) {
    padding: 20px;
  }

  .title {
    font-weight: bold;
    color: #171717;
    margin: 0;
    padding-top: 24px;

    /* Media Query for Mobile Devices */
      @media (max-width: 480px) {
      font-size: 50px;
    }
      
    /* Media Query for low resolution  Tablets, Ipads */
    @media (min-width: 481px) and (max-width: 767px) {
      font-size: 50px;
    }
      
    /* Media Query for Tablets Ipads portrait mode */
    @media (min-width: 768px) and (max-width: 1024px){
      font-size: 50px;
    }
      
    /* Media Query for Laptops and Desktops */
    @media (min-width: 1025px) and (max-width: 1280px){
      font-size: 50px;
    }
      
    /* Media Query for Large screens */
    @media (min-width: 1281px) {
      font-size: 60px;
    }
  }
}
